import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  CardContent,
  Checkbox,
  CardMedia,
  Card,
  Grid,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePDF } from 'react-to-pdf';
import { API_ENDPOINTS } from '../api/apiConfig';
import i18n from 'i18next';
import TableViewIcon from '@mui/icons-material/TableView';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const OrderDetails = () => {
  const location = useLocation();
  const { order, nameUser } = location.state || {};
  const { id, idBillNumber } = useParams();
  const { t } = useTranslation();

  const [orderDetails, setOrderDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [viewMode, setViewMode] = useState('table'); // 'table' or 'card'
  const [editCounts, setEditCounts] = useState({});
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [newCount, setNewCount] = useState(1);

  const { toPDF, targetRef } = usePDF({ filename: `${order.date}.pdf` });

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.ordersOneUser, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: id, idBillNumber, KEY: process.env.REACT_APP_API_KEY }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrderDetails(data);
      } catch (error) {
        console.error('Error fetching order details:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch(API_ENDPOINTS.getProducts, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchOrderDetails();
    fetchProducts();

    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
  }, [id, idBillNumber]);

  const handleCheckboxChange = async (index, checked, orderId) => {
    setLoading(true);
    const updatedOrderDetails = [...orderDetails];
    updatedOrderDetails[index].done = checked;

    try {
      const response = await fetch(`${API_ENDPOINTS.updateOrder}${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ done: checked, KEY: process.env.REACT_APP_API_KEY }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setOrderDetails(updatedOrderDetails);
    } catch (error) {
      console.error('Error updating order status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (orderId) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINTS.deleteOrder}${orderId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ KEY: process.env.REACT_APP_API_KEY }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setOrderDetails(orderDetails.filter(item => item.id !== orderId));
    } catch (error) {
      console.error('Error deleting order item:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCount = (index, countNeed) => {
    const updatedCounts = { ...editCounts, [index]: countNeed };
    setEditCounts(updatedCounts);
  };

  const handleSaveCount = async (index, orderId) => {
    const newCount = editCounts[index];
    setLoading(true);

    try {
      const response = await fetch(`${API_ENDPOINTS.updateOrderCount}${orderId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ countNeed: newCount, KEY: process.env.REACT_APP_API_KEY }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.error(newCount);

      const updatedOrderDetails = [...orderDetails];
      updatedOrderDetails[index].countNeed = newCount;
      setOrderDetails(updatedOrderDetails);
      setEditCounts({ ...editCounts, [index]: undefined });
    } catch (error) {
      console.error('Error updating order count:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedProduct(null);
    setNewCount(1);
  };

  const handleAddItem = async () => {
    if (!selectedProduct) return;

    setLoading(true);

    try {
      const price = selectedProduct.discount ? parseFloat(selectedProduct.discountPrice) : parseFloat(selectedProduct.price);
      const newItem = {
        idUser: id,
        countNeed: newCount,
        amount: selectedProduct.amount || 0,
        imageUrl: selectedProduct.imageUrl || '',
        name: selectedProduct.name || '',
        price,
        saleFor: selectedProduct.sale_for != null
          ? parseFloat(selectedProduct.sale_for) || 0
          : 0,
        type: selectedProduct.types || '',
        done: false,
        idBillNumber: idBillNumber,
        description: selectedProduct.description || '',
      };

      const response = await fetch(API_ENDPOINTS.addOrderItem, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderDetails: [newItem],
          KEY: process.env.REACT_APP_API_KEY
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const newOrderDetail = await response.json();
      setOrderDetails([...orderDetails, newOrderDetail[0]]); // Ensure newOrderDetail[0] is added correctly
      handleCloseDialog();
    } catch (error) {
      console.error('Error adding new item:', error);
    } finally {
      setLoading(false);
    }
  };

  const totalSum = orderDetails.reduce((acc, item) => acc + (item.price * item.countNeed), 0).toFixed(2);
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        {t('orderDetailsFor')} {order.date} - {nameUser}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => toPDF()} style={{ margin: '20px' }}>
        {t('convertToPDF')}
      </Button>
      <Button variant="contained" color="primary" onClick={handleOpenDialog} style={{ margin: '20px' }}>
        {t('addItem')}
      </Button>
      <IconButton onClick={() => setViewMode(viewMode === 'table' ? 'card' : 'table')}>
        {viewMode === 'table' ? <ViewModuleIcon /> : <TableViewIcon />}
      </IconButton>
      {viewMode === 'card' ? (
        <Grid container spacing={4}>
          {orderDetails.map((detail, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={detail.imageUrl}
                  alt={detail.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {detail.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {t('price')}: {detail.price}, {t('amount')}: {detail.amount}, {t('countNeeded')}: {detail.countNeed}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {t('sum')}: {(detail.price * detail.countNeed).toFixed(2)}
                  </Typography>
                  <Checkbox
                    checked={detail.done}
                    onChange={(e) => handleCheckboxChange(index, e.target.checked, detail.id)}
                    color="primary"
                  />
                  <TextField
                    label={t('countNeeded')}
                    type="number"
                    value={editCounts[index] !== undefined ? editCounts[index] : detail.countNeed}
                    onChange={(e) => handleUpdateCount(index, e.target.value)}
                    style={{ marginTop: '10px' }}
                  />
                  <IconButton onClick={() => handleSaveCount(index, detail.id)} color="primary">
                    <SaveIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteItem(detail.id)} color="secondary">
                    <DeleteIcon />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <TableContainer component={Paper} elevation={3} style={{ direction: direction }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('name')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('price')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('amount')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('countNeeded')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('sum')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('done')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('actions')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails.map((detail, index) => (
                <TableRow key={detail.id}>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.name}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.price}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.amount}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}>
                    <TextField
                      label={t('countNeeded')}
                      type="number"
                      value={editCounts[index] !== undefined ? editCounts[index] : detail.countNeed}
                      onChange={(e) => handleUpdateCount(index, e.target.value)}
                      style={{ width: '80px' }}
                    />
                    <IconButton onClick={() => handleSaveCount(index, detail.id)} color="primary">
                      <SaveIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{(detail.price * detail.countNeed).toFixed(2)}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}>
                    <Checkbox
                      checked={detail.done}
                      onChange={(e) => handleCheckboxChange(index, e.target.checked, detail.id)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}>
                    <IconButton onClick={() => handleDeleteItem(detail.id)} color="secondary">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box mt={4}>
        <Typography variant="h5" align="right">
          {t('totalSum')}: {totalSum}
        </Typography>
      </Box>
      {loading && <CircularProgress />}
      <Box ref={targetRef} p={2} style={{ direction: direction }}>
        <Box mt={4} display="flex" justifyContent="center">
          <img src='/logo192.png' width={100} />
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-start">
          <Typography variant="h5">الطلبية من سامر</Typography>
        </Box>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Typography variant="h5">الطلبية ل {nameUser}</Typography>
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <Typography variant="h5">تاريخ الطلبية {order.date}</Typography>
        </Box>
        <TableContainer component={Paper} elevation={3} style={{ direction: direction }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('name')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('price')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('amount')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('countNeeded')}</Typography></TableCell>
                <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{t('sum')}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderDetails.map((detail, index) => (
                <TableRow key={detail.id}>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.name}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.price}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.amount}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{detail.countNeed}</Typography></TableCell>
                  <TableCell align="center" sx={{ verticalAlign: 'middle' }}><Typography variant="h5">{(detail.price * detail.countNeed).toFixed(2)}</Typography></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Typography variant="h5">
            {t('totalSum')}: {totalSum}
          </Typography>
        </Box>
        <Box mt={4} display="flex" justifyContent="center">
          <Typography variant="h5">تاريخ طباعة الطلبية {currentDate}</Typography>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{t('addItem')}</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={products}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setSelectedProduct(newValue);
              console.log("newValue: ", newValue);
            }}
            renderInput={(params) => <TextField {...params} label={t('selectProduct')} />}
            style={{ marginBottom: '20px' }}
          />
          <TextField
            label={t('count')}
            type="number"
            value={newCount}
            onChange={(e) => setNewCount(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleAddItem} color="primary">
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OrderDetails;
