import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, IconButton, Drawer, List, ListItemButton, ListItemText, useMediaQuery, useTheme, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../context/AuthContext'; // Ensure correct path
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json'; // Adjust the path as needed
import translationAR from '../locales/ar/translation.json'; // Adjust the path as needed

// Initialize i18n
i18n.use(initReactI18next).init({
    resources: {
        ar: { translation: translationAR },
        en: { translation: translationEN },
    },
    lng: 'ar', // Default language
    fallbackLng: 'ar',
    interpolation: { escapeValue: false },
});

function Navbar() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const auth = getAuth();
    const { authenticated } = useAuth();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    // State for nested menus
    const [anchorElAddUser, setAnchorElAddUser] = useState(null);
    const [anchorElAddProduct, setAnchorElAddProduct] = useState(null);

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate('/');
        }).catch((error) => {
            // An error happened.
            console.error('Logout failed:', error);
        });
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleMenuOpen = (event, setAnchor) => {
        setAnchor(event.currentTarget);
    };

    const handleMenuClose = (setAnchor) => {
        setAnchor(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <List>
                {authenticated ? (
                    <>
                        <ListItemButton component={RouterLink} to="/home">
                            <ListItemText primary={t('home')} />
                        </ListItemButton>
                        <ListItemButton onClick={(event) => handleMenuOpen(event, setAnchorElAddUser)}>
                            <ListItemText primary={t('addUser')} />
                        </ListItemButton>
                        <Menu
                            anchorEl={anchorElAddUser}
                            open={Boolean(anchorElAddUser)}
                            onClose={() => handleMenuClose(setAnchorElAddUser)}
                        >
                            <MenuItem component={RouterLink} to="/AddNewOwner" onClick={() => handleMenuClose(setAnchorElAddUser)}>
                                {t('addNewOwner')}
                            </MenuItem>
                            <MenuItem component={RouterLink} to="/AddNewCustomer" onClick={() => handleMenuClose(setAnchorElAddUser)}>
                                {t('addNewCustomer')}
                            </MenuItem>
                        </Menu>
                        <ListItemButton onClick={(event) => handleMenuOpen(event, setAnchorElAddProduct)}>
                            <ListItemText primary={t('addProduct')} />
                        </ListItemButton>
                        <Menu
                            anchorEl={anchorElAddProduct}
                            open={Boolean(anchorElAddProduct)}
                            onClose={() => handleMenuClose(setAnchorElAddProduct)}
                        >
                            <MenuItem component={RouterLink} to="/AddProduct" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                {t('addProduct')}
                            </MenuItem>
                            <MenuItem component={RouterLink} to="/type" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                {t('type')}
                            </MenuItem>
                            <MenuItem component={RouterLink} to="/SaleFor" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                {t('saleFor')}
                            </MenuItem>
                        </Menu>
                        <ListItemButton component={RouterLink} to="/SetNewOrder">
                            <ListItemText primary={t('SetNewOrder')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to="/AllUsers">
                            <ListItemText primary={t('users')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to="/users">
                            <ListItemText primary={t('orders')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to="/profile">
                            <ListItemText primary={t('myProfile')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to="/Language">
                            <ListItemText primary={t('language')} />
                        </ListItemButton>

                        <ListItemButton onClick={handleLogout}>
                            <ListItemText primary={t('logout')} />
                        </ListItemButton>
                    </>
                ) : (
                    <>
                        <ListItemButton component={RouterLink} to="/Language">
                            <ListItemText primary={t('language')} />
                        </ListItemButton>
                        <ListItemButton component={RouterLink} to="/login">
                            <ListItemText primary={t('login')} />
                        </ListItemButton>
                    </>
                )}
            </List>
        </Box>
    );

    return (
        <AppBar position="static" color="default">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    <Button component={RouterLink} to="/" color="inherit">{t('shop')}</Button>
                </Typography>
                {isMobile ? (
                    <>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={handleDrawerToggle}
                        >
                            {drawer}
                        </Drawer>
                    </>
                ) : (
                    <Box>
                        {authenticated ? (
                            <>
                                <Button color="inherit" component={RouterLink} to="/home">{t('home')}</Button>
                                <Button
                                    color="inherit"
                                    aria-controls="add-user-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => handleMenuOpen(event, setAnchorElAddUser)}
                                >
                                    {t('addUser')}
                                </Button>
                              
                                <Menu
                                    id="add-user-menu"
                                    anchorEl={anchorElAddUser}
                                    open={Boolean(anchorElAddUser)}
                                    onClose={() => handleMenuClose(setAnchorElAddUser)}
                                >
                                    <MenuItem component={RouterLink} to="/AddNewOwner" onClick={() => handleMenuClose(setAnchorElAddUser)}>
                                        {t('addNewOwner')}
                                    </MenuItem>
                                    <MenuItem component={RouterLink} to="/AddNewCustomer" onClick={() => handleMenuClose(setAnchorElAddUser)}>
                                        {t('addNewCustomer')}
                                    </MenuItem>
                                </Menu>
                                <Button
                                    color="inherit"
                                    aria-controls="add-product-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => handleMenuOpen(event, setAnchorElAddProduct)}
                                >
                                    {t('addProduct')}
                                </Button>
                                <Menu
                                    id="add-product-menu"
                                    anchorEl={anchorElAddProduct}
                                    open={Boolean(anchorElAddProduct)}
                                    onClose={() => handleMenuClose(setAnchorElAddProduct)}
                                >
                                    <MenuItem component={RouterLink} to="/AddProduct" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                        {t('addProduct')}
                                    </MenuItem>
                                    <MenuItem component={RouterLink} to="/type" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                        {t('type')}
                                    </MenuItem>
                                    <MenuItem component={RouterLink} to="/SaleFor" onClick={() => handleMenuClose(setAnchorElAddProduct)}>
                                        {t('saleFor')}
                                    </MenuItem>
                                </Menu>
                                <Button color="inherit" component={RouterLink} to="/SetNewOrder">{t('SetNewOrder')}</Button>

                                <Button color="inherit" component={RouterLink} to="/AllUsers">{t('users')}</Button>
                                <Button color="inherit" component={RouterLink} to="/users">{t('orders')}</Button>
                                <Button color="inherit" component={RouterLink} to="/AllOrder">{t('allOrder')}</Button>

                                <Button color="inherit" component={RouterLink} to="/profile">{t('myProfile')}</Button>
                                <Button color="inherit" component={RouterLink} to="/Language">{t('language')}</Button>
                                <Button></Button>
                                <Button color="inherit" onClick={handleLogout}>{t('logout')}</Button>
                            </>
                        ) : (
                            <>
                                <Button color="inherit" component={RouterLink} to="/Language">{t('language')}</Button>
                                <Button color="inherit" component={RouterLink} to="/login">{t('login')}</Button>
                            </>
                        )}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Navbar;
